import React from 'react'
import { Dropdown, Button, ButtonGroup, Row, Col, Container } from 'reactstrap'
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom'
import { PdfFile } from '../../Components/Utils'
import { Helmet } from 'react-helmet'

export default function Dokument() {
    let { path, url } = useRouteMatch()
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dokument | Brf Lyfta </title>
                <meta
                    name="description"
                    content="Allmäna dokument med information om föreningen och diverse formulär som andrahandsuthyrning mm."
                />
            </Helmet>
            <Row>
                <Col xs="12" md="8">
                    <h1>Dokument</h1>
                    <p>
                        Här hittar du blanketter och mallar som du kan ha
                        användning av.
                    </p>
                    <p>
                        Vill du hyra ut din bostadsrätt eller hyresrätt i andra
                        hand? Eller ska du kanske till och med sälja din
                        bostadsrätt eller säga upp din hyresrätt? Här hittar du
                        några mallar som kan vara till användning för dig.
                    </p>

                    <b>Information från avfallsavdelningen 2024/2025</b>
                    <br/>
                    <PdfFile
                        filename="Dispens avfallskvarn"
                        filepath="/files/document/Dispens_for_avfallskvarn_-_fastighetsagare.pdf"
                    ></PdfFile> <br/>
                    <PdfFile
                        filename="Felanmälan sopsug"
                        filepath="/files/document/jarfalla-2024-2025/Felanmalan-sopsug.pdf"
                    ></PdfFile>
                    <br/>
                    <PdfFile
                        filename="Julbrev från barkarbystaden"
                        filepath="/files/document/jarfalla-2024-2025/Julbrev-Barkarbystaden 2024.pdf"
                    ></PdfFile>
                    <br/>
                    <PdfFile
                        filename="Schema mobil återvinningscentral Järfälla 2025"
                        filepath="/files/document/jarfalla-2024-2025/Schema-2025.pdf"
                    ></PdfFile>
                    <br/>
                    <PdfFile
                        filename="Skåp för farligt avfall och kemikalier"
                        filepath="/files/document/jarfalla-2024-2025/Skap-for-farligt avfall-och-kemikalier..pdf"
                    ></PdfFile>
                    <br/>
                    <PdfFile
                        filename="SOPSUG FÖR AVFALL FRÅN VERKSAMHETERNA"
                        filepath="/files/document/jarfalla-2024-2025/Sopsug-for-avfall-fran-verksamheterna.pdf"
                    ></PdfFile>
                    <br/>
                    <PdfFile
                        filename="Sorteringsguide"
                        filepath="/files/document/jarfalla-2024-2025/Sorteringsguide-2022-webb.pdf"
                    ></PdfFile>
                    <br />
                    <br />

                    <b>Trivsel och ordningsregler</b>
                    <br />
                    <PdfFile
                        filename="TRIVSEL OCH ORDNINGSREGLER"
                        filepath="/files/document/trivsel-och-ordningsregler.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Energideklaration</b>
                    <br />
                    <PdfFile
                        filename="Energideklaration"
                        filepath="/files/document/energideklaration2021.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Garage</b>
                    <br />
                    <PdfFile
                        filename="Information om garage - skyldigheter och laddboxar"
                        filepath="/files/document/Stadgar samt trivsel- och ordningsregler/Garageinformation-laddbox.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="INFORMATION KAMERAÖVERVAKNING I GARAGET"
                        filepath="/files/document/Stadgar samt trivsel- och ordningsregler/kamera-garage.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Mallar</b>
                    <br />
                    <PdfFile
                        filename="Fullmakt till föreningstämma"
                        filepath="/files/document/fullmakt_foreningsstamma_230329.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Information till boende</b>
                    <br />
                    <PdfFile
                        filename="Nyckelinformation"
                        filepath="/files/document/nyckelinformation.pdf"
                    ></PdfFile> <br/>
                    <PdfFile
                        filename="Storlholmen kontaktuppgifter"
                        filepath="/files/document/Information till boende/Trapphusinformation-Storholmen.pdf"
                    ></PdfFile> <br/>
                    <PdfFile
                        filename="Nytt avtal med Tele2 2023-08-08"
                        filepath="/files/document/Information till boende/tele2-gruppavtal-2023-08-08.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Guide Sopsortering"
                        filepath="/files/document/Information till boende/JK-Sorteringsguide_2018.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Sorteringsguide i Barkarbystaden"
                        filepath="/files/document/Information till boende/Sorteringsguide-i-barkarbystaden.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Skötselanvisning Easy-Vent"
                        filepath="/files/document/Information till boende/easy-vent-skotselnvisning.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Bopärm"
                        filepath="/files/document/Information till boende/boparm.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Tecknande av el-abonnemang"
                        filepath="/files/document/Information till boende/Tecknande-av-el-abonnemang.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Garantibevis"
                        filepath="/files/document/Information till boende/Garantibevis.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="HEJ! NU KAN DU BESTÄLLA
DINA TJÄNSTER FRÅN COM HEM"
                        filepath="/files/document/Information till boende/ComHem.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="HEJ OCH VÄLKOMMEN TILL COM HEM"
                        filepath="/files/document/Information till boende/ComHem2.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Matavfallssystem"
                        filepath="/files/document/Information till boende/matavfallssystem.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Stadgar samt trivsel- och ordningsregler</b>

                    <br />
                    <PdfFile
                        filename="Välkomstbrev"
                        filepath="/files/document/Stadgar samt trivsel- och ordningsregler/valkomstbrev.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Stadgar"
                        filepath="/files/document/stadgar.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>MSB - Brandinformation</b>
                    <br />
                    <PdfFile
                        filename="Om det börjar brinna"
                        filepath="/files/document/MSB - Brandinformation/d958c399cb5ab222d7f282554f997bd39292eabf772c3e7ca01af79096e95ef7.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Skydda dig och dina grannar mot brand"
                        filepath="/files/document/MSB - Brandinformation/e0a8820facce717977d714986ec065e63366757a08759488b6cfd7a3cac7e2f8.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Andrahandsuthyrning</b>
                    <br />
                    <PdfFile
                        filename="andrahandsuthyrning"
                        filepath="/files/document/andrahandsuthyrning.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Information om Barkarbystaden</b>
                    <br />
                    <PdfFile
                        filename="Järfälla kommun informerar om veddestabron"
                        filepath="/files/document/Information-om-Barkarbystaden/veddestabron.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="Region Stockholm informerar om tunnelbanan till Barkarby"
                        filepath="/files/document/Information-om-Barkarbystaden/tunnelbana-barkarby.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Ekonomisk plan</b>
                    <br />
                    <PdfFile
                        filename="Ekonomisk plan"
                        filepath="/files/document/eas/40ae0dd95c6f3eb44a9c39bc54d47ede160cd01c5020bd8a021649c6a7a63d3e.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Årsredovisning</b>
                    <br />
                    <PdfFile
                        filename="2019"
                        filepath="/files/document/eas/ars2019.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="2019 Revisorns utlåtande"
                        filepath="/files/document/eas/ars2019-ru.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <PdfFile
                        filename="2018 PM från revisorerna"
                        filepath="/files/document/eas/2018-pm-r.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="2018"
                        filepath="/files/document/eas/ars2018.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <PdfFile
                        filename="2017"
                        filepath="/files/document/eas/ars2017.pdf"
                    ></PdfFile>
                    <br />
                    <br />
                    <b>Stämmoprotokoll</b>
                    <br />
                    <PdfFile
                        filename="2023-10-05 Extrastämma"
                        filepath="/files/document/protkoll-stamma/20231005.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="2023-05-29"
                        filepath="/files/document/protkoll-stamma/20230529.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="2019-05-15"
                        filepath="/files/document/eas/sp2019.pdf"
                    ></PdfFile>
                    <br />
                    <PdfFile
                        filename="2018-11-15 Extrastämma"
                        filepath="/files/document/eas/2018sp-es.pdf"
                    ></PdfFile>
                    <br />

                    <hr />
                </Col>
            </Row>
        </Container>
    )
}
